import QuilityAPI from "../../../store/API/QuilityAPI"

export type LicensedAgent =       {
  "Recruit": string,
  "UplineAgent": string,
  "KeyLeader": string,
  "BaseshopOwner": string,
  "SFGDirect": string,
  "ContractSubmitDate": string,
  "Licensed": boolean
  ID: string,
}

export const getLicensedAgents = async (options: {
  search: string,
  datePeriod: {
	startDateFormat: string | null, 
	endDateFormat: string | null,
	period: string | null,
  }, // TODO: Make sure we even need this
}, agentCode: string): Promise<LicensedAgent[]> => {
	return QuilityAPI.getRequest(`/api/private/stats/onboarded_applicants/${agentCode}`, {
		statType: 'baseshop',
		startDate: options.datePeriod.startDateFormat,
		endDate: options.datePeriod.endDateFormat,
	})
		.then((response) => response.data)
		.then((data: Record<string, string>[]) => {
			return data
				.map<LicensedAgent>((item) => ({
					Recruit: item.name,
					UplineAgent: item.UplineAgentName,
					KeyLeader: item.contract_submitted,
					BaseshopOwner: item.BaseShopOwnerAgentName,
					SFGDirect: item.SFGDirectAgentName,
					ContractSubmitDate: item.contract_submitted,
					Licensed: item.coverted === "Y",
					ID: item.BaseShopOwnerAgentCode + ' ' + item.SFGDirectAgentCode + ' ' + item.UplineAgentCode + ' ' + item.contract_submitted
				}))
				// ID contains most of the required fields. There is an old data which may 
				// not have this fields, so we filter them out
				.filter((item) => item.ID.includes('undefined') === false)
		})
}